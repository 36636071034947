import React, { FC } from 'react';

import { Box, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ProductCategory } from '@graphql/generated';

const useStyles = makeStyles({
  container: {
    padding: '1rem 0 0 1rem'
  },
  chip: {
    zIndex: 1,
    marginLeft: '0.5rem',

    '&:first-of-type': {
      marginLeft: 0
    },
    '&:not(:only-child)': {
      maxWidth: '95px'
    }
  }
});

interface IProps {
  productCategories: ProductCategory[];
}

const Chips: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {props.productCategories.map((productChip) => (
        <Chip
          key={productChip.id}
          label={productChip.name}
          title={productChip.name}
          className={classes.chip}
          color="secondary"
          size="small"
        />
      ))}
    </Box>
  );
};

export default Chips;
