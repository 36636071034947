import React, { FC, useContext } from 'react';

import { QueryResult } from '@apollo/client';
import { Box, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Feedback from '@components/layout/ui/Feedback';
import CategoriesMenu from '@components/shared/CategoriesMenu';
import Product from '@components/shared/Product';
import { Product as GraphQLProductInterface, ProductsQuery } from '@graphql/generated';

import { UIContext } from '../../../../context/UIContext';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: '100%',
    marginBottom: '4rem'
  },
  grid: {
    backgroundColor: '#f7f7f7',
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }
}));

interface IProps {
  products: QueryResult<ProductsQuery>;
}

const ProductList: FC<IProps> = (props) => {
  const { products } = props;
  const classes = useStyles();

  const uiContext = useContext(UIContext);

  //material-ui.com/components/grid/#negative-margin
  return (
    <>
      <Box className={classes.container} style={{ padding: 8 }}>
        <CategoriesMenu direction="horizontal" />

        <Feedback loading={products.loading || uiContext.search.isTyping} error={!!products.error}>
          <Grid container className={classes.grid} spacing={2} justify="flex-start">
            {/* hack to prevent content jumping (re-rendering) */}
            {products?.data?.products?.map((product: GraphQLProductInterface) => {
              return (
                <Grid key={product.id} item xs={6} sm="auto">
                  <Product product={product} />
                </Grid>
              );
            })}
          </Grid>
        </Feedback>
      </Box>
    </>
  );
};
export default ProductList;
