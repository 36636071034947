import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Enum_Orderitem_Unit_Type, Product } from '@graphql/generated';
import { getUnitPrice } from '@helpers/products';

const useStyles = makeStyles({
  ribbon: {
    width: '120px',
    height: '120px',
    overflow: 'hidden',
    position: 'absolute',

    '&:before, &:after': {
      position: 'absolute',
      zIndex: -1,
      content: '',
      display: 'block',
      border: '5px solid #c52025'
    }
  },

  ribbonTopRight: {
    top: '-10px',
    right: '-10px',

    '&:before, &:after': {
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',

      position: 'absolute',
      zIndex: -1,
      content: '',
      display: 'block',
      border: '5px solid #c52025'
    },

    '&:before': {
      top: 0,
      left: 0
    },

    '&:after': {
      bottom: 0,
      right: 0
    },

    '& > span': {
      position: 'absolute',
      display: 'block',
      top: '30px',
      left: '30px',
      width: '105px',
      backgroundColor: '#333',
      color: '#fff',
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      transform: 'rotate(45deg)'
    }
  }
});

interface IProps {
  product: Product;
  unitType: Enum_Orderitem_Unit_Type;
}

const Ribbon: FC<IProps> = (props) => {
  const classes = useStyles();

  const { product, unitType } = props;
  const { discount_enabled, discount_price, sale_price, unit_discount_price } = product;

  function getDiscount() {
    if (discount_enabled) {
      const salePrice = Enum_Orderitem_Unit_Type.Unit ? getUnitPrice(product, false) : sale_price;

      const discountPrice =
        unitType === Enum_Orderitem_Unit_Type.Weight ? discount_price : unit_discount_price;

      const priceDiff = salePrice - discountPrice;
      return Math.floor((priceDiff * 100) / salePrice);
    }
  }

  const discount = getDiscount();

  return (
    <Box className={`${classes.ribbon} ${classes.ribbonTopRight}`}>
      <span>{discount}% OFF</span>
    </Box>
  );
};

export default Ribbon;
