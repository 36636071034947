import React, { FC } from 'react';

import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'next/image';

import { getBannerURL } from '@config/utils';

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '538px',
    pointerEvents: 'none',

    // backgroundImage: `url(/images/banner.jpg)`,
    // backgroundRepeat: 'no-repeat',
    // backgroundColor: 'transparent',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center center',

    [theme.breakpoints.down('md')]: {
      height: '380px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '255px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '132px'
    }
  }
}));

interface IProps {
  banner: { url?: string };
}

const Hero: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Image
        src={getBannerURL(props.banner)}
        layout="fill"
        objectFit="cover"
        alt="banner"
        priority
      />
    </Box>
  );
};

export default Hero;
