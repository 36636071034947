import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, Button, Card, CardActions, CardContent, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Image from 'next/image';

import analytics from '@config/analytics';
import { PHONE_NUMBER } from '@config/constants';
import { currency, getImageURL } from '@config/utils';
import { CartContext } from '@context/CartContext';
import { Enum_Orderitem_Unit_Type, Product as GraphQLProductInterface } from '@graphql/generated';
import { sendWhatsappMessage } from '@helpers/misc';
import { EProductOperation, getPrice, getQuantity, getUnitPrice } from '@helpers/products';

import Chips from '../Chips';
import ItemCounter from '../ItemCounter';
import Ribbon from '../Ribbon';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'relative',
    maxWidth: '220px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  img: {
    padding: '2rem !important'
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',
    marginTop: 'auto',

    '&.MuiCardActions-spacing > :not(:first-child)': {
      marginLeft: '0 !important'
    }
  },
  pricesContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  realPrice: {
    textDecoration: 'line-through'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem',
    marginTop: '0.5rem',

    '& > button:first-child': {
      marginRight: '0.5rem'
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',

      '& > button:first-child': {
        marginRight: 0,
        marginBottom: '0.5rem'
      }
    }
  },
  price: {
    fontWeight: 'bold',

    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem'
    }
  },
  cartButton: {
    // width: '120px',
    width: '100%',
    height: '32px',
    borderRadius: '4px',

    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

interface IProps {
  product: GraphQLProductInterface;
}

const Product: FC<IProps> = (props) => {
  const { product } = props;
  const {
    id,
    name,
    description,
    img,
    product_categories,
    sale_by_unit_enabled,
    sale_by_weight_enabled,

    discount_price,
    discount_enabled,
    unit_discount_price,
    sale_price,

    contact_us_enabled
  } = product;

  const classes = useStyles();
  const cartContext = useContext(CartContext);

  const cartItem = cartContext.items.find((item) => item.product.id === id);

  const [unitType, setUnitType] = useState<Enum_Orderitem_Unit_Type>(
    sale_by_unit_enabled ? Enum_Orderitem_Unit_Type.Unit : Enum_Orderitem_Unit_Type.Weight
  );

  // it handles items got from cookies
  useEffect(() => {
    if (cartItem && cartItem.unitType !== unitType) {
      setUnitType(cartItem?.unitType);
    }
  }, [cartItem, unitType]);

  const weightSelected = unitType === Enum_Orderitem_Unit_Type.Weight;
  const unitSelected = unitType === Enum_Orderitem_Unit_Type.Unit;

  const cartItemQuantity = cartItem?.quantity || 0;

  function onChangeType(selectedType: Enum_Orderitem_Unit_Type) {
    setUnitType(selectedType);
    cartContext.removeItem({ product, unitType, quantity: 0 });

    analytics.event('Alterar Modalidade Produto', {
      event_category: 'Card Produto',
      event_label: selectedType
    });
  }

  function addItem() {
    const quantity = getQuantity(product, unitType);
    cartContext.addItem({ product, unitType, quantity });

    analytics.event('Adicionar Produto', {
      event_category: 'Card Produto',
      event_label: unitType,
      value: Number(product.id)
    });
  }

  function onCounterChange(quantity: number, operation: EProductOperation) {
    const isAdding = operation === EProductOperation.ADD;

    if (isAdding) {
      cartContext.addItem({ product, unitType, quantity });
    } else {
      cartContext.removeItem({ product, unitType, quantity });
    }

    let eventAction = isAdding ? 'Incrementar Item' : 'Decrementar Item';
    if (!quantity) {
      eventAction = 'Zerar Item';
    }
    analytics.event(eventAction, {
      event_category: 'Card Produto',
      event_label: unitType,
      value: Number(product.id)
    });
  }

  function shouldDisableAddButton() {
    if (!sale_by_unit_enabled && !sale_by_weight_enabled) {
      return true;
    }

    const productPrice = getPrice(product, unitType);
    return !productPrice;
  }

  const hasWeightDiscount = discount_enabled && discount_price;
  const hasUnitDiscount = discount_enabled && unit_discount_price;

  return (
    <Card className={classes.root}>
      {hasWeightDiscount && weightSelected && (
        <Ribbon product={product} unitType={Enum_Orderitem_Unit_Type.Weight} />
      )}
      {hasUnitDiscount && unitSelected && (
        <Ribbon product={product} unitType={Enum_Orderitem_Unit_Type.Unit} />
      )}
      <Chips productCategories={product_categories} />

      <Image
        className={classes.img}
        title={name}
        alt={name}
        src={getImageURL(img)}
        width={300}
        height={300}
        unoptimized
      />

      <CardContent>
        <Typography variant="h5" component="h5">
          {name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </CardContent>

      <CardActions className={classes.actionsContainer}>
        {contact_us_enabled ? (
          <Button
            className={classes.cartButton}
            variant="contained"
            startIcon={<WhatsAppIcon />}
            size="small"
            color="primary"
            onClick={() =>
              sendWhatsappMessage(`Olá, tenho interesse no Produto "${name}"`, PHONE_NUMBER)
            }>
            Fale conosco
          </Button>
        ) : (
          <>
            <Box className={classes.pricesContainer}>
              {hasWeightDiscount && weightSelected && (
                <Box>
                  <Typography variant="caption" color="textSecondary" className={classes.realPrice}>
                    {currency(sale_price)}/kg
                  </Typography>
                </Box>
              )}

              {hasUnitDiscount && unitSelected && (
                <Box>
                  <Typography variant="caption" color="textSecondary" className={classes.realPrice}>
                    {currency(getUnitPrice(product, false))}/un
                  </Typography>
                </Box>
              )}

              <Box className={classes.buttonsContainer}>
                {sale_by_unit_enabled && (
                  <Button
                    size="small"
                    variant={unitType === Enum_Orderitem_Unit_Type.Unit ? 'contained' : 'outlined'}
                    onClick={() => onChangeType(Enum_Orderitem_Unit_Type.Unit)}
                    color="secondary">
                    {currency(getPrice(product, Enum_Orderitem_Unit_Type.Unit))}/un
                  </Button>
                )}
                {sale_by_weight_enabled && (
                  <Button
                    size="small"
                    variant={
                      unitType === Enum_Orderitem_Unit_Type.Weight ? 'contained' : 'outlined'
                    }
                    onClick={() => onChangeType(Enum_Orderitem_Unit_Type.Weight)}
                    color="secondary">
                    {currency(getPrice(product, Enum_Orderitem_Unit_Type.Weight))}/kg
                  </Button>
                )}
              </Box>
            </Box>

            {cartItemQuantity === 0 ? (
              <Button
                className={classes.cartButton}
                variant="contained"
                startIcon={<ShoppingCartIcon />}
                size="small"
                color="primary"
                onClick={addItem}
                disabled={shouldDisableAddButton()}>
                Adicionar
              </Button>
            ) : (
              <ItemCounter
                product={product}
                value={cartItemQuantity}
                unitType={unitType}
                onChange={onCounterChange}
              />
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default Product;
