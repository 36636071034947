import React, { FC } from 'react';

import { QueryResult } from '@apollo/client';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CategoriesMenu from '@components/shared/CategoriesMenu';
import { ProductsQuery } from '@graphql/generated';

import ProductList from './ProductList';

const useStyles = makeStyles<Theme>(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #ddd'
  }
}));

interface IProps {
  products: QueryResult<ProductsQuery>;
}

const Showcase: FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CategoriesMenu direction="vertical" />
      <ProductList products={props.products} />
    </Box>
  );
};
export default Showcase;
