import React, { FC } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import { ReactComponent as ErrSVG } from './undraw_page_not_found.svg';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      maxWidth: '500px',
      margin: '0 auto'
    }
  })
);

const Err: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <ErrSVG /> */}
      error
    </div>
  );
};

export default Err;
