import React, { useContext, useEffect, useMemo } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { Box } from '@material-ui/core';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';

import { apolloClient } from '@config/apollo';
import { PATHS } from '@config/paths';
import { ProductCategoryContext } from '@context/ProductCategoryContext';
import { UIContext } from '@context/UIContext';
import {
  ProductCategoriesQuery,
  ProductCategoriesDocument,
  ProductsQuery,
  ProductCategory,
  useProductsQuery,
  TemplateBannerQuery,
  TemplateBannerDocument
} from '@graphql/generated';
import { getURLParam } from '@helpers/misc';
import { OFFERS_CATEGORY } from '@helpers/productCategories';

import Hero from './Hero';
import Showcase from './Showcase';

let firstRenderFromRoot = null;
const Root: NextPage<IStaticProps> = (props) => {
  const ssrSelectedCategory = props.selectedCategory;
  const ssrProductCategories = props.productCategoriesResponse?.data?.productCategories;
  const ssrProducts = props.productsResponse?.data?.products;

  const router = useRouter();

  const uiContext = useContext(UIContext);
  const productsContext = useContext(ProductCategoryContext);
  const {
    setProductCategories,
    selectedCategory,
    setSelectedCategory
  } = productsContext.productCategories;

  useEffect(() => {
    if (ssrProductCategories) {
      setProductCategories(ssrProductCategories as ProductCategory[]);
    }
  }, [setProductCategories, ssrProductCategories]);

  useEffect(() => {
    if (selectedCategory) {
      return;
    }

    // INFO: router.query.category first render is undefined (router.isReady)
    const queryCategory = getURLParam(router.asPath, 'category');
    firstRenderFromRoot = firstRenderFromRoot === null && !queryCategory;

    const category = queryCategory || ssrSelectedCategory;
    if (category) {
      setSelectedCategory(category);
    }
  }, [selectedCategory, router, setSelectedCategory, ssrSelectedCategory]);

  useEffect(() => {
    if (PATHS.ROOT === router.asPath && selectedCategory) {
      const url = { pathname: PATHS.ROOT, query: `category=${selectedCategory}` };
      if (firstRenderFromRoot) {
        url.query += '&firstRenderFromRoot=true';
        firstRenderFromRoot = false;
      }

      router.replace(url, undefined, { shallow: true, scroll: false });
    }
  }, [router, selectedCategory]);

  // ----- //

  const isOffersCategory = selectedCategory === OFFERS_CATEGORY.name;
  const searchQuery = uiContext.search.query;
  const shouldOmitProductCategory = isOffersCategory || !!searchQuery;
  const productCategory = selectedCategory || '';

  const productsResult = useProductsQuery({
    skip: !selectedCategory || (ssrProducts?.length && !searchQuery),
    variables: {
      productCategory: shouldOmitProductCategory ? undefined : productCategory,
      offersOnly: isOffersCategory || undefined,
      searchQuery: searchQuery
    }
  });

  const products = useMemo(() => {
    return {
      ...productsResult,
      data: {
        products: productsResult.data?.products || ssrProducts
      }
    };
  }, [productsResult, ssrProducts]);

  return (
    <Box>
      <Hero banner={props.banner} />
      <Showcase products={products} />
    </Box>
  );
};

type IParams = { productCategory: string[] };

interface IStaticProps {
  banner: { url?: string };
  selectedCategory?: string;
  productsResponse?: ApolloQueryResult<ProductsQuery>;
  productCategoriesResponse?: ApolloQueryResult<ProductCategoriesQuery>;
}
// export const getStaticPaths: GetStaticPaths<IParams> = async () => {
//   const productCategoriesResponse = await apolloClient.query<
//     ProductCategoriesQuery,
//     ProductCategoriesQueryVariables
//   >({
//     query: ProductCategoriesDocument
//   });

//   const paths = productCategoriesResponse.data.productCategories.map((productCategory) => {
//     const params: IParams = { productCategory: [productCategory.slug] };
//     return { params };
//   });

//   return {
//     paths: paths,
//     fallback: true
//   };
// };

export const getStaticProps: GetStaticProps<IStaticProps, IParams> = async () => {
  const productCategoriesResponse = await apolloClient.query<ProductCategoriesQuery>({
    query: ProductCategoriesDocument
  });

  const templateBannerResponse = await apolloClient.query<TemplateBannerQuery>({
    query: TemplateBannerDocument
  });

  const banner = templateBannerResponse?.data?.template?.banner;

  // const contextCategory = context.params?.productCategory?.[0];
  const fallbackCategory = productCategoriesResponse.data.productCategories?.[0]?.slug;
  // const selectedCategory = contextCategory || fallbackCategory;
  const selectedCategory = fallbackCategory;

  // const productsResponse = await apolloClient.query<ProductsQuery, ProductsQueryVariables>({
  //   query: ProductsDocument,
  //   variables: { productCategory: selectedCategory }
  // });

  return {
    props: {
      banner,
      selectedCategory,
      productCategoriesResponse,
      productsResponse: null
    },
    revalidate: 3600
  };
};

// type IProps = InferGetStaticPropsType<typeof getStaticProps>;

export default Root;
