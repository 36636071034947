import React, { FC } from 'react';

import { CircularProgress, Backdrop } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      '&.-fullscreen': {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh'
      }
    },
    progress: {
      margin: theme.spacing(2),
      '&.-fullscreen': {
        color: '#fff'
      }
    }
  })
);

interface IProps {
  fullscreen?: boolean;
}

const Loading: FC<IProps> = (props) => {
  const classes = useStyles();
  const { fullscreen } = props;

  const fullscreenCls = fullscreen ? '-fullscreen' : '';

  return (
    <>
      <Backdrop open={!!fullscreen} style={{ zIndex: 9998 }} />
      <div style={{ zIndex: 9999 }} className={`${classes.root} ${fullscreenCls}`}>
        <CircularProgress className={`${classes.progress} ${fullscreenCls}`} />
      </div>
    </>
  );
};

export default Loading;
