import React, { FC, ReactNode } from 'react';

import Err from '../Err';
import Loading from '../Loading';

interface IProps {
  loading?: boolean;
  error?: boolean;
  children: ReactNode;
}

const Feedback: FC<IProps> = (props) => {
  if (props.loading) {
    return <Loading />;
  }

  if (props.error) {
    return <Err />;
  }

  return <>{props.children}</>;
};

export default Feedback;
